import type { ServerResponse } from 'http'

export class CacheControlHelper {
  config = {
    default: 'public, s-maxage=600, stale-while-revalidate=59, stale-if-error=86400',
    none: 'no-cache, no-store, max-age=0, must-revalidate',
  } as const

  environmentsWithNoCache = ['dev-local']

  public setOnResponse({ res, disabled = false }: { res: ServerResponse; disabled: boolean }) {
    if (disabled) return res.setHeader('Cache-Control', this.getConfig('none'))
    res.setHeader('Cache-Control', this.getConfig())
  }

  public getConfig(fixedOption?: keyof typeof this.config) {
    if (!this.canEnvironmentBeCached()) return this.config.none
    if (fixedOption) return this.config[fixedOption]
    return this.config.default
  }

  private canEnvironmentBeCached() {
    return !this.environmentsWithNoCache.includes(process.env.ECS_ENV as string)
  }
}

const cacheControlHelper = new CacheControlHelper()

export default cacheControlHelper
