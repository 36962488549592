import { legacyCustomComponents } from '@/legacy/blocks/registry'

import { registeredPagueVelozBaseComponents } from './base/registry'
import { registeredPagueVelozBlogComponents } from './blog/registry'

export const registeredPagueVelozComponents = [
  ...legacyCustomComponents,
  ...registeredPagueVelozBaseComponents,
  ...registeredPagueVelozBlogComponents,
]
