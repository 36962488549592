const TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span']

export const baseTitleInput = {
  name: 'title',
  type: 'object',
  defaultValue: {
    text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
    tag: 'h2',
  },
  subFields: [
    {
      name: 'text',
      type: 'string',
      friendlyName: 'Texto',
    },
    {
      name: 'tag',
      friendlyName: 'Tag',
      type: 'string',
      enum: TAGS,
    },
  ],
}
