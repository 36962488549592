import { LIST_LAYOUT } from '../constants'

export const typeInput = {
  name: 'type',
  type: 'string',
  friendlyName: 'Tipo de lista',
  defaultValue: 'unordered',
  enum: [
    { value: 'ordered', label: 'Ordenado' },
    { value: 'unordered', label: 'Não ordenado' },
  ],
}

export const layoutInput = {
  name: 'layout',
  type: 'string',
  friendlyName: 'Tipo de Lista',
  hideFromUI: true,
  defaultValue: LIST_LAYOUT.list,
}

export const itemsInput = {
  name: 'items',
  type: 'list',
  defaultValue: [
    {
      content:
        '<p>Mauris ut aliquet purus. Etiam justo purus, posuere quis urna in, vestibulum eleifend tellus.</p>',
    },
  ],
  subFields: [
    {
      name: 'content',
      friendlyName: 'Item',
      type: 'richText',
    },
  ],
}

export const defaultListInputs = [typeInput, layoutInput, itemsInput]
