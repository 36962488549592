import { isPreviewing } from '@/libs/builder/sdk-react'

interface CheckIsNotFoundProps<T> {
  page: T
  urlPath: string
}

export function checkIsNotFound<T>({ page, urlPath }: CheckIsNotFoundProps<T>) {
  return !page && !isPreviewing(urlPath)
}
