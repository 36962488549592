import { useIsMobile } from '../useIsMobile'

const hasNavigator = () => typeof navigator !== 'undefined'
const getUserAgent = () => (hasNavigator() ? navigator.userAgent : 'SSR')
const hasTouchPoints = () =>
  Boolean(hasNavigator() && navigator.maxTouchPoints && navigator.maxTouchPoints > 1)

export const getDeviceDetected = (userAgent: NavigatorID['userAgent']) => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i))
  const isIpad = () => Boolean(userAgent.match(/Macintosh/i) && hasTouchPoints())
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i) || isIpad())
  const isOperaMini = () => Boolean(userAgent.match(/Opera Mini/i))
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i))
  const isSSR = () => Boolean(userAgent.match(/SSR/i))
  const isMobile = () => Boolean(isAndroid() || isIos() || isOperaMini() || isWindows())
  const isDesktop = () => Boolean(!isMobile() && !isSSR())
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
  }
}

export const useDeviceDetector = () => getDeviceDetected(getUserAgent())

export const useMobileDetector = (maxScreenSize = '1024px') => {
  const isMobileByNavigator = useDeviceDetector().isMobile()
  const isMobileScreenSize = useIsMobile(maxScreenSize)

  return isMobileByNavigator && isMobileScreenSize
}

export const useDesktopDetector = () => !useMobileDetector()
