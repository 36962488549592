import dynamic from 'next/dynamic'

import { RegisteredComponentBuilder } from '@/ui/shared/helpers'

import { BLOCK_NAMES } from '../../blockNames'

import { defaultListInputs } from './default.inputs'

const builder = new RegisteredComponentBuilder({
  name: BLOCK_NAMES.list.default,
  component: dynamic<never>(() => import('..').then((mod) => mod.ListBlock)),
  defaultStyles: { marginTop: '16px', marginBottom: '16px', marginLeft: '0px', marginRight: '0px' },
  inputs: defaultListInputs,
})

export const registeredList = builder
  .buildTitleInput()
  .buildSubtitleInput()
  .buildAlignmentInput({ name: 'position' })
  .getResult()
