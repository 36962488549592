import type { IncomingHttpHeaders } from 'http'

import { getDeviceDetected } from '@/legacy/hooks/useDeviceDetector'

export type Device = 'android' | 'ios' | 'desktop' | undefined

function getDeviceFromLocal(userAgent?: string) {
  if (!userAgent) return
  if (getDeviceDetected(userAgent).isAndroid()) return 'android'
  if (getDeviceDetected(userAgent).isIos()) return 'ios'
  return 'desktop'
}

function getDeviceFromCloudFront(headers: IncomingHttpHeaders) {
  if (headers['cloudfront-is-android-viewer'] === 'true') return 'android'
  if (headers['cloudfront-is-ios-viewer'] === 'true') return 'ios'
  return 'desktop'
}

export function getDevice(headers: IncomingHttpHeaders): Device {
  if (process.env.ECS_ENV === 'dev-local') return getDeviceFromLocal(headers['user-agent'])
  else return getDeviceFromCloudFront(headers)
}
