import type { Input } from '@/libs/builder/sdk-react/types'

export function baseButtonShowIf(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: Map<string, any>,
) {
  if (!options.has('hasButton')) return true
  return options.get('hasButton')
}

export const baseHasButtonInput: Input = {
  name: 'hasButton',
  type: 'boolean',
  friendlyName: 'Exibir botão',
  defaultValue: true,
}

export const baseButtonInput: Input = {
  name: 'button',
  type: 'object',
  friendlyName: 'Botão',
  defaultValue: {
    label: 'Consultar CPF',
    link: 'https://www.serasa.com.br/entrar',
    modifier: 'solid',
  },
  subFields: [
    {
      name: 'label',
      type: 'string',
      friendlyName: 'Texto',
    },
    {
      name: 'link',
      type: 'string',
    },
    {
      name: 'ariaLabel',
      type: 'string',
      friendlyName: 'Descritivo pro leitor de tela',
      advanced: true,
    },
    {
      name: 'modifier',
      type: 'string',
      friendlyName: 'Estilo',
      enum: [
        { label: 'Primário', value: 'solid' },
        { label: 'Contornado', value: 'outline' },
        { label: 'Fantasma', value: 'ghost' },
        { label: 'Primário invertido', value: 'solid-invert' },
        { label: 'Link', value: 'link' },
      ],
    },
    {
      name: 'targetId',
      type: 'string',
      friendlyName: 'ID do elemento para scroll',
      advanced: true,
    },
  ],
}
