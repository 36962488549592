export const baseAlignmentInput = {
  name: 'alignment',
  friendlyName: 'Alinhamento do conteúdo',
  type: 'string',
  defaultValue: 'left',
  enum: [
    { label: 'Esquerda', value: 'left' },
    { label: 'Centro', value: 'center' },
    { label: 'Direita', value: 'right' },
  ],
  advanced: true,
}
