export const HERO_NAME = 'GenericHero'

import {
  analytics,
  buttonAriaLabelBuilderInput,
  hasButtonBuilderInputs,
  heroSubtitleBuilderInput,
  heroTitleBuilderInput,
  titleTagBuilderInputSubFields,
  titleTextBuilderInputSubFields,
} from '@/legacy/blocks/constants'
import { builderInputsBlock } from '@/legacy/blocks/utils'
import {
  containerBackgroundColorBuilderInput,
  containerBackgroundColorHexBuilderInput,
} from '@/legacy/components/Container'

import { imageBuilderInput } from '@/legacy/components/Media/constants.register'

import type { Input } from '@/libs/builder/sdk'

const titleMaxLengthValidator = `;(function (options) {
  const hasSubtitle = options.get('showSubtitle')
  const MAX_LENGTH = hasSubtitle ? 46 : 64
  const content = options.get('title').toJSON()
  if (content.text.length > MAX_LENGTH) {
    if (!hasSubtitle) return alert('Conteúdo deve ter no máximo 64 caracteres.')
    alert('Conteúdo deve ter no máximo 46 caracteres.')
  }
})(options)`

const titleInputs: Input = {
  ...heroTitleBuilderInput,
  onChange: titleMaxLengthValidator,
  subFields: [
    {
      ...titleTextBuilderInputSubFields,
      helperText: 'Deve conter 64 caracteres no máximo ou 46 caracteres se houver subtítulo.',
    },
    titleTagBuilderInputSubFields,
  ],
}

const subtitleMaxLengthValidator = `;(function (options) {
  const MAX_LENGTH = 80
  const content = options.get('subtitle').toJSON()
  const regex = /^.{0,80}$/
  if (content.text.length > MAX_LENGTH) alert('Conteúdo deve ter no máximo 80 caracteres.')
})(options)`

const subtitleInputs: Input[] = [
  {
    name: 'showSubtitle',
    type: 'boolean',
    friendlyName: 'Exibir subtítulo',
    helperText: 'Desative para que o título possa ter até 64 caracteres.',
    required: true,
    defaultValue: true,
  },
  {
    ...heroSubtitleBuilderInput,
    onChange: subtitleMaxLengthValidator,
    subFields: [
      {
        ...titleTextBuilderInputSubFields,
        helperText: 'Deve conter no máximo 80 caracteres.',
      },
      titleTagBuilderInputSubFields,
    ],
    showIf: (options) => options.get('showSubtitle') === true,
  },
]

const imageInput: Input = {
  name: 'images',
  type: 'object',
  friendlyName: 'Imagens',
  subFields: [
    {
      ...imageBuilderInput,
      name: 'desktop',
      friendlyName: 'Desktop',
    },
    {
      ...imageBuilderInput,
      name: 'mobile',
      friendlyName: 'Mobile',
    },
  ],
}

const secondaryBackgroundInput: Input[] = [
  {
    ...containerBackgroundColorBuilderInput,
    name: 'secondaryBackground',
    friendlyName: 'Cor de fundo - Secundária',
    defaultValue: 'snow',
    advanced: true,
  },
  {
    ...containerBackgroundColorHexBuilderInput,
    name: 'secondaryBackgroundColorHex',
    advanced: true,
    showIf: (options) => options.get('secondaryBackground') === 'custom',
  },
]

const primaryBackgroundInput: Input[] = [
  {
    ...containerBackgroundColorBuilderInput,
    name: 'primaryBackground',
    friendlyName: 'Cor de fundo - Primária',
    defaultValue: 'primary',
    advanced: true,
  },
  {
    ...containerBackgroundColorHexBuilderInput,
    name: 'primaryBackgroundColorHex',
    advanced: true,
    showIf: (options) => options.get('primaryBackground') === 'custom',
  },
]

export const heroInputs: Input[] = [
  titleInputs,
  ...subtitleInputs,
  ...hasButtonBuilderInputs,
  { ...buttonAriaLabelBuilderInput, showIf: (options) => options.get('hasButton') },
  imageInput,
  ...builderInputsBlock({
    withFontSize: false,
    withBackground: false,
    withTitle: false,
    withSubtitle: false,
    withPosition: false,
    isHero: true,
    hasAdvancedOptions: true,
  }),
  ...primaryBackgroundInput,
  ...secondaryBackgroundInput,
  analytics,
]

export const IS_PRODUCTION_READY = true
