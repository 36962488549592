import dynamic from 'next/dynamic'

import { RegisteredComponentBuilder } from '@/ui/shared/helpers'

import { BLOCK_NAMES } from '../../blockNames'

const builder = new RegisteredComponentBuilder({
  name: BLOCK_NAMES.media.default,
  component: dynamic<never>(() => import('.').then((mod) => mod.MediaBlock)),
  defaultStyles: { marginTop: '32px', marginBottom: '32px', marginLeft: '0px', marginRight: '0px' },
})

export const registeredMedia = builder
  .buildTitleInput()
  .buildSubtitleInput()
  .buildContentInput()
  .buildButtonInputs()
  .buildAlignmentInput({ name: 'position' })
  .buildMediaInputs(
    { name: 'type' },
    {
      showIf: function (options) {
        return options.get('type') === 'image'
      },
    },
    {
      showIf: function (options) {
        return options.get('type') === 'video'
      },
    },
  )
  .getResult()
