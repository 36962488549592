import type { Input } from '@/libs/builder/sdk-react/types'

export function baseVideoShowIf(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: Map<string, any>,
) {
  if (!options.has('mediaType')) return true
  return options.get('mediaType') === 'video'
}

export function baseImageShowIf(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: Map<string, any>,
) {
  if (!options.has('mediaType')) return true
  return options.get('mediaType') === 'image'
}

export const baseMediaTypeInput = {
  name: 'mediaType',
  type: 'string',
  friendlyName: 'Tipo de mídia',
  defaultValue: 'image',
  enum: [
    { label: 'Imagem', value: 'image' },
    { label: 'Vídeo', value: 'video' },
  ],
}

export const baseImageInput: Input = {
  name: 'image',
  type: 'object',
  defaultValue: {
    url: 'https://cdn.builder.io/api/v1/image/assets%2F8758a9e80a454a6eb972cf9c829376a2%2Ff1bcfabf4448417182ce5886db8053b2',
  },
  subFields: [
    { name: 'url', type: 'file', allowedFileTypes: ['svg', 'png', 'jpg', 'jpeg'] },
    { name: 'alt', type: 'string' },
  ],
}

export const baseVideoInput: Input = {
  name: 'video',
  type: 'object',
  subFields: [{ name: 'url', type: 'string' }],
}
