import dynamic from 'next/dynamic'

import { RegisteredComponentBuilder } from '@/ui/shared/helpers'

import { BLOCK_NAMES } from '../blockNames'

const builder = new RegisteredComponentBuilder({
  name: BLOCK_NAMES.textBlock,
  component: dynamic<never>(() => import('.').then((mod) => mod.TextBlock)),
  defaultStyles: { marginTop: '16px', marginBottom: '16px', marginLeft: '0px', marginRight: '0px' },
})

export const registeredText = builder
  .buildTitleInput()
  .buildSubtitleInput()
  .buildContentInput()
  .buildButtonInputs()
  .buildAlignmentInput({ name: 'position' })
  .getResult()
