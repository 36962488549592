import type { Input, RegisteredComponent } from '@/libs/builder/sdk-react/types'

import {
  baseAlignmentInput,
  baseBackgroundInput,
  baseButtonInput,
  baseButtonShowIf,
  baseContentInput,
  baseHasButtonInput,
  baseImageInput,
  baseImageShowIf,
  baseMediaTypeInput,
  baseTitleInput,
  baseVideoInput,
  baseVideoShowIf,
} from './base-inputs'

export class RegisteredComponentBuilder {
  private inputs: Array<Input> = []
  readonly customInputs: Array<Input> = []

  constructor(private readonly options: RegisteredComponent) {
    if (options.inputs) {
      this.customInputs.push(...options.inputs)
      delete options.inputs
    }
  }

  buildTitleInput(options: Partial<Input> = {}) {
    this.inputs.push({
      ...baseTitleInput,
      ...options,
    })
    return this
  }

  buildSubtitleInput(options: Partial<Input> = {}) {
    this.inputs.push({
      ...baseTitleInput,
      name: 'subtitle',
      friendlyName: 'Subtítulo',
      defaultValue: { ...baseTitleInput.defaultValue, tag: 'h3' },
      ...options,
    })
    return this
  }

  buildContentInput(options: Partial<Input> = {}) {
    this.inputs.push({
      ...baseContentInput,
      ...options,
    })
    return this
  }

  buildButtonInputs(hasButtonOptions: Partial<Input> = {}, buttonOptions: Partial<Input> = {}) {
    this.inputs.push({
      ...baseHasButtonInput,
      ...hasButtonOptions,
    })
    this.inputs.push({
      ...baseButtonInput,
      showIf: baseButtonShowIf,
      ...buttonOptions,
    })
    return this
  }

  buildMediaInputs(
    mediaTypeOptions: Partial<Input> = {},
    imageOptions: Partial<Input> = {},
    videoOptions: Partial<Input> = {},
  ) {
    this.inputs.push({
      ...baseMediaTypeInput,
      ...mediaTypeOptions,
    })
    this.inputs.push({
      ...baseImageInput,
      showIf: baseImageShowIf,
      ...imageOptions,
    })
    this.inputs.push({
      ...baseVideoInput,
      showIf: baseVideoShowIf,
      ...videoOptions,
    })
    return this
  }

  buildImageInput(options: Partial<Input> = {}) {
    this.inputs.push({
      ...baseImageInput,
      ...options,
    })
    return this
  }

  buildVideoInput(options: Partial<Input> = {}) {
    this.inputs.push({
      ...baseVideoInput,
      ...options,
    })
    return this
  }

  buildAlignmentInput(options: Partial<Input> = {}) {
    this.inputs.push({
      ...baseAlignmentInput,
      ...options,
    })
    return this
  }

  buildBackgroundInput(options: Partial<Input> = {}) {
    this.inputs.push({
      ...baseBackgroundInput,
      ...options,
    })
    return this
  }

  getInputs(): Input[] {
    return [...this.inputs, ...this.customInputs]
  }

  getResult(): RegisteredComponent {
    return {
      inputs: [...this.inputs, ...this.customInputs],
      defaultStyles: {
        marginTop: '0px',
        marginBottom: '0px',
        marginLeft: '0px',
        marginRight: '0px',
      },
      ...this.options,
    }
  }
}
