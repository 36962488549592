import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { shouldHideUnready } from '@/ui/shared/helpers'

import { HERO_NAME, heroInputs, IS_PRODUCTION_READY } from './registry.inputs'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.GenericHero)),
  {
    name: HERO_NAME,
    inputs: heroInputs,
    hideFromInsertMenu: shouldHideUnready(IS_PRODUCTION_READY),
  },
)
