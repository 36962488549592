import type { RegisteredComponent } from '@/libs/builder/sdk-react'

import { registeredList } from './List/default/default.registry'
import { registeredMedia } from './Media/MediaBlock/registry'
import { registeredText } from './Text/registry'

export const legacyCustomComponents: RegisteredComponent[] = [
  registeredText,
  registeredMedia,
  registeredList,
]
