import type { IncomingMessage, ServerResponse } from 'http'

import { getDevice } from '@/legacy/core/utils/getDevice'

import { isPreviewing } from '@/libs/builder/sdk-react'
import { type BuilderContent } from '@/libs/builder/sdk-react/types'

import { cacheControlHelper, checkIsNotFound } from '@/ui/shared/helpers'

export default class GetServerSidePropsHelper {
  urlPath: string

  constructor(
    private res: ServerResponse,
    private req: IncomingMessage,
    readonly url: string,
    customUrlPathTreatment: (url: string) => string = (url) => url,
  ) {
    const urlWithoutQueryStrings = url.split('?').shift() || ''
    this.urlPath = customUrlPathTreatment(urlWithoutQueryStrings)
  }

  buildResponse<T extends { page: BuilderContent | null }>(props: T) {
    const device = getDevice(this.req.headers)

    const isNotFound = checkIsNotFound<typeof props.page>({
      page: props.page,
      urlPath: this.url,
    })

    cacheControlHelper.setOnResponse({
      res: this.res,
      disabled: isNotFound || isPreviewing(this.url),
    })

    return {
      notFound: isNotFound,
      props: {
        ...props,
        device,
      },
    }
  }
}
