import type { Input } from '@/libs/builder/sdk-react/types'

export const baseBackgroundInput: Input = {
  name: 'background',
  type: 'string',
  friendlyName: 'Cor de fundo',
  defaultValue: 'main',
  enum: [
    { value: 'main', label: 'Branco (#FFFFFF)' },
    { value: 'brandLowest', label: 'Rosa claro (#FFEDF2)' },
    { value: 'neutralLowest', label: 'Cinza claro (#F5F7F9)' },
  ],
}
